.tabbar {
  box-sizing: content-box;
  // padding-bottom: calc(constant(safe-area-inset-bottom));
  // padding-bottom: calc(env(safe-area-inset-bottom));
  height: 96px;
  flex-shrink: 0;
  background: #ffffff;
  border-top: 1px solid #eeeeee;

  :global {
    .adm-tab-bar-item-icon {
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .adm-tab-bar-item {
      padding: 12px 0 8px;
    }
    .adm-tab-bar-item-title {
      font-size: 18px;
      line-height: 26px;
      margin-top: 9px;
    }
  }

  .tabbarIcon {
    width: 36px;
    height: 36px;
  }
}
