.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #121212;
  font-size: 28px;
  font-weight: 400;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0);
  z-index: 1000;
  // &-icon {
  //   width: 130px;
  //   height: 100px;
  //   background: url('')
  //     no-repeat;
  //   background-size: contain;
  // }
}
