@import "./reset";

body,
html {
  width: 100%;
  height: 100%;
  background-color: #f4f4f4 !important;
  // padding-bottom: calc(
  //   60px + constant(safe-area-inset-bottom)
  // ); /* 兼容 iOS < 11.2 */
  // padding-bottom: calc(
  //   60px + env(safe-area-inset-bottom)
  // ); /* 兼容 iOS >= 11.2 */
  box-sizing: border-box;
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;
  color: transparent;
}

.navSticky {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100;
  background: #f7fafd;
}
