.layout {
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .content {
    flex: 1;
    background: #f7f8fc;
    overflow: auto;
  }

  // .safeArea {
  //   height: calc(68px + constant(safe-area-inset-bottom));
  //   height: calc(68px + env(safe-area-inset-bottom));
  //   background: #ffffff;
  // }
}
